@import url("https://fonts.googleapis.com/css2?family=Afacad:ital,wght@0,400..700;1,400..700&family=Rosario:ital,wght@0,300..700;1,300..700&display=swap");
.homeSection {
  padding-top: 110px;
  margin-left: 100px;
  margin-right: 100px;
}
.homeSection .mainSection {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
.homeSection .mainSection .hospitalImg {
  margin-top: 40px;
  position: relative;
}
.homeSection .mainSection .hospitalImg img {
  border-radius: 15px;
  width: 850px;
  height: 450px;
}
.homeSection .mainSection .hospitalInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  margin-top: 250px;
}
.homeSection .mainSection .hospitalInfo h2 {
  font-size: 40px;
  font-weight: 700;
  color: white;
  margin-bottom: 1rem;
}
.homeSection .mainSection .hospitalInfo p {
  font-size: 15px;
  font-weight: 400;
  width: 750px;
  color: white;
  margin-bottom: 1rem;
}
.homeSection .HaboutSection {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
}
.homeSection .HaboutSection .imageSec {
  display: flex;
  z-index: 1000;
}
.homeSection .HaboutSection .imageSec .img1 {
  position: relative;
  border-radius: 10px;
  width: 550px;
  height: 500px;
}
.homeSection .HaboutSection .imageSec .img2 {
  position: absolute;
  margin-top: 400px;
  width: 180px;
  animation: floatUpDown 3s ease-in-out infinite;
  /* Adjust timing and animation effect as needed */
}
.homeSection .HaboutSection .aboutHospital {
  background-color: #F5FBFF;
  width: 600px;
  margin-top: 70px;
  margin-left: -60px;
  z-index: 1001;
  border-radius: 10px;
  text-align: start;
}
.homeSection .HaboutSection .aboutHospital .insideAbout {
  margin-left: 50px;
  margin-right: 40px;
  animation: slideIn 1s ease-out forwards;
  animation-delay: 0.5s;
  /* Delay each child element's animation */
}
.homeSection .HaboutSection .aboutHospital .insideAbout h5 {
  margin-top: 40px;
  color: #51A7EF;
}
.homeSection .HaboutSection .aboutHospital .insideAbout h2 {
  font-size: 55px;
  color: #1B3C74;
  font-weight: 600;
}
.homeSection .HaboutSection .aboutHospital .insideAbout p {
  font-size: 20px;
}
.homeSection .HaboutSection .aboutHospital .insideAbout .VisMis .Vision,
.homeSection .HaboutSection .aboutHospital .insideAbout .VisMis .Mission {
  display: flex;
  flex-direction: row;
}
.homeSection .HaboutSection .aboutHospital .insideAbout .VisMis .Vision .iconVis,
.homeSection .HaboutSection .aboutHospital .insideAbout .VisMis .Vision .iconMis,
.homeSection .HaboutSection .aboutHospital .insideAbout .VisMis .Mission .iconVis,
.homeSection .HaboutSection .aboutHospital .insideAbout .VisMis .Mission .iconMis {
  margin-top: 20px;
  margin-right: 20px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0 15px 25px -10px rgba(0, 0, 0, 0.15);
  width: 100px;
  height: 70px;
}
.homeSection .HaboutSection .aboutHospital .insideAbout .VisMis .Vision .iconVis img,
.homeSection .HaboutSection .aboutHospital .insideAbout .VisMis .Vision .iconMis img,
.homeSection .HaboutSection .aboutHospital .insideAbout .VisMis .Mission .iconVis img,
.homeSection .HaboutSection .aboutHospital .insideAbout .VisMis .Mission .iconMis img {
  width: 48px;
  height: 48px;
}
.homeSection .HaboutSection .aboutHospital .insideAbout .VisMis .Vision .detail h3,
.homeSection .HaboutSection .aboutHospital .insideAbout .VisMis .Mission .detail h3 {
  color: #1B3C74;
}
.homeSection .HaboutSection .aboutHospital .insideAbout .VisMis .Vision .detail p,
.homeSection .HaboutSection .aboutHospital .insideAbout .VisMis .Mission .detail p {
  font-size: 20px;
}
.homeSection .HaboutSection .butnAbout {
  margin-top: 20px;
}
.homeSection .HaboutSection .butnAbout button {
  background-color: #2490EB;
  color: white;
  border-radius: 5px;
  border: none;
  height: 60px;
  width: 200px;
  font-size: 20px;
}
.homeSection .specializedCare {
  margin-left: 20px;
  margin-right: 20px;
}
.homeSection .specializedCare .headingSpecialized {
  color: #1B3C74;
}
.homeSection .specializedCare .headingSpecialized h2 {
  font-size: 45px;
}
.homeSection .specializedCare .cardSection {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}
.homeSection .specializedCare .cardSection .careCard {
  margin: 10px;
  width: 350px;
  height: 400px;
  border-radius: 10px;
  box-shadow: 0 15px 25px -10px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-color: white;
  transition: all 0.3s ease-in-out;
  /* Add hover effect */
}
.homeSection .specializedCare .cardSection .careCard:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 25px -10px rgba(0, 0, 0, 0.3);
}
.homeSection .specializedCare .cardSection .careCard img {
  margin-top: 20px;
}
.homeSection .specializedCare .cardSection .careCard h4 {
  align-items: start;
  text-align: start;
  margin-top: 20px;
  color: #1B3C74;
}
.homeSection .specializedCare .cardSection .careCard p {
  width: 300px;
  font-size: 20px;
}
.homeSection .ourTeam {
  margin-top: 100px;
  position: relative;
  padding-top: 100px;
}
.homeSection .ourTeam .img1 {
  position: absolute;
  left: 0;
  top: 50px;
  animation: moveUpDown 5s infinite;
}
.homeSection .ourTeam .img2 {
  position: absolute;
  right: 0;
  top: 100px;
  animation: moveUpDown 5s infinite;
}
.homeSection .ourTeam h4 {
  text-align: start;
  color: #2490EB;
  font-size: 30px;
}
.homeSection .ourTeam h2 {
  text-align: start;
  color: #1B3C74;
  font-size: 45px;
}
.homeSection .ourTeam .mainforflex {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.homeSection .ourTeam .mainforflex .teamMember img {
  width: 100%;
  background-color: #F5FBFF;
}
.homeSection .ourTeam .mainforflex .teamMember h3 {
  margin-top: 10px;
  margin-bottom: 0px;
  color: #1B3C74;
  font-weight: 600;
  font-size: 28px;
}
.homeSection .ourTeam .mainforflex .teamMember p {
  margin-top: 0px;
  color: #4B5160;
  font-size: 20px;
}
.homeSection .chooseSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  gap: 50px;
}
.homeSection .chooseSection .leftSide {
  text-align: start;
  align-items: start;
  width: 700px;
  animation: slideIn 1s ease-out forwards;
  animation-delay: 0.5s;
}
.homeSection .chooseSection .leftSide h4 {
  color: #51A7EF;
}
.homeSection .chooseSection .leftSide h2 {
  color: #1B3C74;
  font-size: 55px;
}
.homeSection .chooseSection .leftSide p {
  font-size: 20px;
}
.homeSection .chooseSection .leftSide .pointsDiv {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}
.homeSection .chooseSection .leftSide .pointsDiv .point {
  display: flex;
  flex-direction: row;
  gap: 40px;
}
.homeSection .chooseSection .leftSide .pointsDiv .point svg {
  margin-top: 20px;
  width: 50px;
  height: 50px;
  color: white;
  background-color: #1B3C74;
}
.homeSection .chooseSection .leftSide .pointsDiv .point .content h3 {
  margin: 0px;
  padding: 0px;
  color: #1B3C74;
  font-size: 30px;
  margin-bottom: 10px;
}
.homeSection .chooseSection .leftSide .pointsDiv .point .content p {
  font-size: 20px;
  color: #4B5160;
  width: 500px;
  font-weight: 400;
}
.homeSection .chooseSection .rightSide {
  animation: slideIn 1s ease-out forwards;
  animation-delay: 0.5s;
}
.homeSection .chooseSection .rightSide img {
  margin-top: 200px;
  width: 100%;
  height: 400px;
  border-radius: 10px;
}
.homeSection .testimonialSection {
  width: 100%;
  padding: 20px 0 0;
  background-color: #f9f9f9;
  /* Light grey background */
  box-sizing: border-box;
}
.homeSection .react-multiple-carousel__arrow {
  margin-left: -50px;
  /* Adjust the value to move the buttons away from the testimonial */
}
.homeSection .react-multiple-carousel__arrow--right {
  margin-right: -50px;
  /* Adjust the value to move the button away from the testimonial */
}
.homeSection .react-multiple-carousel__arrow::before {
  color: #2490EB;
  /* Set the desired color for the arrows */
}
.homeSection .react-multi-carousel-track {
  padding-bottom: 40px;
}
.homeSection button {
  background-color: #F5FBFF;
}
.homeSection .review-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 15px;
  /* Spacing between slides */
  margin-top: 20px;
}
.homeSection .review {
  max-width: 400px;
  height: 290px;
  background-color: #ffffff;
  /* White background for the review card */
  border-radius: 10px;
  border: 1px solid #c7c3c3;
  text-align: start;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Subtle shadow for depth */
  padding-top: 20px;
  position: relative;
  margin-top: 10px;
}
.homeSection .review:hover {
  border-color: #2490EB;
  /* Change border color on hover */
}
.homeSection .review-avatar {
  width: 60px;
  /* Adjust size as needed */
  height: 60px;
  /* Adjust size as needed */
  border-radius: 50%;
  /* Circular avatar */
  border: 2px solid #ffffff;
  /* White border around the avatar */
  position: absolute;
  /* Position avatar over the top edge of the card */
  top: -30px;
  /* Half the height of the avatar to sit above the card */
  left: 20px;
  /* Spacing from the left edge of the card */
}
.homeSection .review-content {
  padding: 20px 20px 20px;
  /* Padding to account for the avatar position */
}
.homeSection .review-name {
  font-size: 20px;
  color: #1B3C74;
  /* Darker text for the name */
  margin-bottom: 5px;
}
.homeSection .review-location {
  font-size: 18px;
  color: #2490EB;
  /* Lighter text for the location */
  margin-bottom: 0px;
}
.homeSection .review-text {
  font-style: italic;
  color: #555555;
  /* Medium grey for the review text */
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-70px);
    /* Adjust the distance of movement */
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(50px);
    /* Start from 50px below */
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    /* Slide in to the original position */
  }
}
@keyframes floatUpDown {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
    /* Move up slightly */
  }
  100% {
    transform: translateY(0px);
  }
}
/* Home.css */
@media (max-width: 1024px) {
  .homeSection {
    padding-top: 50px;
    margin-left: 40px;
    margin-right: 40px;
  }
  .homeSection .mainSection .hospitalImg img {
    width: 750px;
    /* Adjusted width */
    height: 400px;
    /* Adjusted height */
  }
  .homeSection .mainSection .hospitalInfo {
    margin-top: 180px;
  }
  .homeSection .HaboutSection {
    margin-top: 50px;
  }
  .homeSection .HaboutSection .imageSec .img1 {
    width: 500px;
    /* Adjusted width */
    height: 450px;
    /* Adjusted height */
  }
  .homeSection .HaboutSection .imageSec .img2 {
    margin-top: 20px;
    width: 150px;
    /* Adjusted width */
    height: auto;
  }
  .homeSection .HaboutSection .aboutHospital {
    width: 600px;
    /* Adjusted width */
    margin-top: 50px;
    margin-left: -70px;
    text-align: start;
  }
  .homeSection .HaboutSection .aboutHospital .insideAbout {
    margin-left: 50px;
    margin-right: 30px;
  }
  .homeSection .HaboutSection .aboutHospital .insideAbout .VisMis .Vision .iconVis,
  .homeSection .HaboutSection .aboutHospital .insideAbout .VisMis .Vision .iconMis,
  .homeSection .HaboutSection .aboutHospital .insideAbout .VisMis .Mission .iconVis,
  .homeSection .HaboutSection .aboutHospital .insideAbout .VisMis .Mission .iconMis {
    margin-top: 20px;
    margin-right: 20px;
    width: 100px;
    height: 70px;
  }
  .homeSection .HaboutSection .aboutHospital .insideAbout .VisMis .Vision .iconVis img,
  .homeSection .HaboutSection .aboutHospital .insideAbout .VisMis .Vision .iconMis img,
  .homeSection .HaboutSection .aboutHospital .insideAbout .VisMis .Mission .iconVis img,
  .homeSection .HaboutSection .aboutHospital .insideAbout .VisMis .Mission .iconMis img {
    width: 48px;
    height: 48px;
  }
  .homeSection .HaboutSection .aboutHospital .insideAbout .VisMis .Vision .detail h3,
  .homeSection .HaboutSection .aboutHospital .insideAbout .VisMis .Mission .detail h3 {
    color: #1B3C74;
  }
  .homeSection .HaboutSection .aboutHospital .insideAbout .VisMis .Vision .detail p,
  .homeSection .HaboutSection .aboutHospital .insideAbout .VisMis .Mission .detail p {
    font-size: 20px;
  }
  .homeSection .chooseSection {
    gap: 5px;
  }
}
@media (max-width: 1300px) {
  .homeSection {
    padding-top: 50px;
    margin-left: 40px;
    margin-right: 40px;
  }
}
/* Home.css */
@media (max-width: 768px) {
  .homeSection {
    padding-top: 50px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .homeSection .mainSection {
    flex-direction: column;
    /* Change to column layout */
    align-items: center;
    /* Center align items */
    text-align: center;
    /* Center align text */
  }
  .homeSection .mainSection .hospitalImg img {
    border-radius: 10px;
    /* Adjusted border radius */
    margin-top: 20px;
    /* Adjusted margin */
  }
  .homeSection .mainSection .hospitalImg img img {
    width: 300px;
    /* Adjusted width to fit container */
    height: 300px;
    /* Maintain aspect ratio */
  }
  .homeSection .mainSection .hospitalInfo {
    margin-top: 80px;
    /* Adjusted margin */
    position: absolute;
    /* Reset position */
    width: 600px;
  }
  .homeSection .mainSection .hospitalInfo p {
    width: 600px;
  }
  .homeSection .HaboutSection {
    flex-direction: column;
    /* Change to column layout */
    align-items: center;
    /* Center align items */
  }
  .homeSection .HaboutSection .imageSec {
    flex-direction: column;
    /* Change to column layout */
    align-items: center;
    /* Center align items */
  }
  .homeSection .HaboutSection .imageSec .img1 {
    width: 550px;
    /* Adjusted width to fit container */
    height: 500px;
    /* Maintain aspect ratio */
    border-radius: 10px;
    /* Adjusted border radius */
    margin-top: 20px;
    /* Adjusted margin */
  }
  .homeSection .HaboutSection .imageSec .img2 {
    margin-top: 400px;
    /* Adjusted margin */
    margin-left: -420px;
    width: 180px;
    /* Adjusted width to fit container */
    height: 180px;
    /* Maintain aspect ratio */
  }
  .homeSection .HaboutSection .aboutHospital {
    width: 100%;
    /* Adjusted width to fit container */
    margin-top: 30px;
    /* Adjusted margin */
    margin-left: 0;
    /* Reset margin */
    text-align: start;
  }
  .homeSection .HaboutSection .aboutHospital .insideAbout {
    margin-left: 40px;
    /* Reset margin */
    margin-right: 30px;
    /* Reset margin */
  }
  .homeSection .HaboutSection .aboutHospital .insideAbout .VisMis {
    display: flex;
    flex-direction: row;
  }
  .homeSection .HaboutSection .aboutHospital .insideAbout .VisMis .iconMis,
  .homeSection .HaboutSection .aboutHospital .insideAbout .VisMis .iconVis {
    margin-top: 20px;
    margin-right: 20px;
    width: 120px !important;
    height: 50px !important;
  }
  .homeSection .HaboutSection .aboutHospital .insideAbout .VisMis .iconMis img,
  .homeSection .HaboutSection .aboutHospital .insideAbout .VisMis .iconVis img {
    width: 35px !important;
    height: 35px !important;
  }
  .homeSection .specializedCare .headingSpecialized h2 {
    font-size: 35px;
  }
  .homeSection .specializedCare .cardSection {
    display: flex;
    flex-direction: column;
  }
  .homeSection .ourTeam {
    margin-top: 50px;
    /* Adjust margin for smaller screens */
  }
  .homeSection .ourTeam h4 {
    text-align: start;
    color: #2490EB;
    font-size: 24px;
    /* Reduce font size for smaller screens */
  }
  .homeSection .ourTeam h2 {
    text-align: start;
    color: #1B3C74;
    font-size: 36px;
    /* Reduce font size for smaller screens */
  }
  .homeSection .ourTeam .mainforflex {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* Display team members in a single column */
    row-gap: 20px;
    /* Add gap between team members */
    -moz-column-gap: 20px;
         column-gap: 20px;
  }
  .homeSection .ourTeam .mainforflex .teamMember img {
    width: 100%;
    background-color: #F5FBFF;
  }
  .homeSection .ourTeam .mainforflex .teamMember h3 {
    margin-bottom: 5px;
    /* Adjust margin for smaller screens */
    color: #1B3C74;
    font-weight: 600;
    font-size: 24px;
    /* Reduce font size for smaller screens */
  }
  .homeSection .ourTeam .mainforflex .teamMember p {
    margin-top: 5px;
    /* Adjust margin for smaller screens */
    color: #4B5160;
    font-size: 16px;
    /* Reduce font size for smaller screens */
  }
  .homeSection .chooseSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    gap: 20px;
    text-align: start;
    margin-left: 10px;
    margin-right: 10px;
  }
  .homeSection .chooseSection .leftSide {
    text-align: start;
    align-items: center;
    width: 100%;
  }
  .homeSection .chooseSection .leftSide h2 {
    font-size: 40px;
  }
  .homeSection .chooseSection .leftSide p {
    font-size: 16px;
  }
  .homeSection .chooseSection .leftSide .pointsDiv {
    margin-top: 20px;
  }
  .homeSection .chooseSection .leftSide .pointsDiv .point {
    flex-direction: row;
    gap: 20px;
    align-items: center;
    text-align: start;
  }
  .homeSection .chooseSection .leftSide .pointsDiv .point svg {
    margin-top: 0px;
    width: 40px;
    height: 40px;
  }
  .homeSection .chooseSection .leftSide .pointsDiv .point .content h3 {
    font-size: 24px;
  }
  .homeSection .chooseSection .leftSide .pointsDiv .point .content p {
    font-size: 16px;
    width: auto;
    margin-bottom: 0;
  }
  .homeSection .chooseSection .rightSide img {
    margin-top: 10px;
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  .homeSection .testimonialSection .review {
    height: 300px;
  }
  .homeSection .testimonialSection .react-multiple-carousel__arrow {
    margin-left: -20px;
    /* Adjust the value to move the buttons away from the testimonial */
  }
  .homeSection .testimonialSection .react-multiple-carousel__arrow--right {
    margin-right: -20px;
    /* Adjust the value to move the button away from the testimonial */
  }
}
@media (max-width: 600px) {
  .homeSection {
    padding-top: 100px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .homeSection .mainSection .hospitalImg img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-top: 10px;
    height: 350px;
  }
  .homeSection .mainSection .hospitalInfo {
    margin-top: 150px;
    width: 100%;
  }
  .homeSection .mainSection .hospitalInfo h2 {
    font-size: 25px;
  }
  .homeSection .mainSection .hospitalInfo p {
    font-size: 15px;
    width: 300px;
    /* Adjusted width to fit container */
  }
  .homeSection .HaboutSection .imageSec .img1 {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-top: 10px;
  }
  .homeSection .HaboutSection .imageSec .img2 {
    margin-top: 250px;
    width: 150px;
    height: auto;
    margin-left: -280px;
  }
  .homeSection .HaboutSection .aboutHospital {
    width: 100%;
    margin-top: 20px;
    text-align: start;
  }
  .homeSection .HaboutSection .aboutHospital .insideAbout {
    margin-left: 20px;
    margin-right: 10px;
  }
  .homeSection .HaboutSection .aboutHospital .insideAbout h5 {
    font-size: 20px;
  }
  .homeSection .HaboutSection .aboutHospital .insideAbout h2 {
    font-size: 30px;
  }
  .homeSection .HaboutSection .aboutHospital .insideAbout p {
    font-size: 18px;
  }
  .homeSection .HaboutSection .aboutHospital .insideAbout .VisMis {
    display: flex;
    flex-direction: column;
  }
  .homeSection .ourTeam {
    margin-top: 50px;
    /* Adjust margin for smaller screens */
  }
  .homeSection .ourTeam h4 {
    text-align: start;
    color: #2490EB;
    font-size: 24px;
    /* Reduce font size for smaller screens */
  }
  .homeSection .ourTeam h2 {
    text-align: start;
    color: #1B3C74;
    font-size: 36px;
    /* Reduce font size for smaller screens */
  }
  .homeSection .ourTeam .mainforflex {
    display: grid;
    grid-template-columns: 1fr;
    /* Display team members in a single column */
    row-gap: 20px;
    /* Add gap between team members */
  }
  .homeSection .ourTeam .mainforflex .teamMember img {
    width: 100%;
    background-color: #F5FBFF;
  }
  .homeSection .ourTeam .mainforflex .teamMember h3 {
    margin-bottom: 5px;
    /* Adjust margin for smaller screens */
    color: #1B3C74;
    font-weight: 600;
    font-size: 24px;
    /* Reduce font size for smaller screens */
  }
  .homeSection .ourTeam .mainforflex .teamMember p {
    margin-top: 5px;
    /* Adjust margin for smaller screens */
    color: #4B5160;
    font-size: 16px;
    /* Reduce font size for smaller screens */
  }
}/*# sourceMappingURL=Home.css.map */