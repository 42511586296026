@import url("https://fonts.googleapis.com/css2?family=Afacad:ital,wght@0,400..700;1,400..700&family=Rosario:ital,wght@0,300..700;1,300..700&display=swap");


.ourTeamSection {
  background-color: #f0f4f7;
  padding: 20px;
  padding-top: 80px;
  // font-family: "Afacad", sans-serif;

}

.topSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 400px;
  background-image: url("https://assets-global.website-files.com/6551ed212e2ea32fb46bb7c8/65520ded00f687d201f5dc4d_bg.png");

  .underline {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
    z-index: 1001;
  }

  .img1 {
    position: absolute;
    left: 0;
    z-index: 1000;
    animation: moveUpDown 5s infinite;

  }

  .img2 {
    position: absolute;
    right: 0;
    z-index: 1000;
    animation: moveUpDown 5s infinite;

  }
}

.ourTeam {
  margin-top: 100px;
  position: relative;
  margin-left: 50px;
  margin-right: 50px;

  .img1 {
    position: absolute;
    left: 0;
    top: 50px;
    animation: moveUpDown 5s infinite;
  }

  .img2 {
    position: absolute;
    right: 0;
    top: 100px;
    animation: moveUpDown 5s infinite;
  }

  h4 {
    text-align: start;
    color: #2490EB;
    font-size: 30px;
  }

  h2 {
    text-align: start;
    color: #1B3C74;
    font-size: 45px;
  }

  .mainforflex {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 10px;

    .teamMember {

      img {
        width: 100%;
        background-color: #F5FBFF;
      }

      h3 {
        margin-bottom: 0px;
        color: #1B3C74;
        font-weight: 600;
        font-size: 35px;
      }

      p {
        margin-top: 0px;
        color: #4B5160;
        font-size: 20px;
      }
    }
  }

}

.underline h5 {
  color: #51A7EF;
  font-size: 25px;

}

.underline p {
  border-bottom: 2px solid #51A7EF;
  width: 200px;
  margin-top: 10px;
  margin-right: 20px;
}

.gratitude h3 {
  color: #1B3C74;
  font-size: 75px;
  z-index: 1002;

}

@media (max-width: 768px) {
  .ourTeamSection {
    padding-top: 40px;
  }

  .topSection {
    height: 300px;
    background-size: cover;
    background-position: center;

    .gratitude {
      h3 {
        font-size: 25px;
      }
    }

    .img1,
    .img2 {
      width: 50px;
      top: 170px;

    }

  }

  .ourTeam {
    margin-top: 50px;
    /* Adjust margin for smaller screens */

    h4 {
      text-align: start;
      color: #2490EB;
      font-size: 24px;
      /* Reduce font size for smaller screens */
    }

    h2 {
      text-align: start;
      color: #1B3C74;
      font-size: 36px;
      /* Reduce font size for smaller screens */
    }

    .mainforflex {
      display: grid;
      grid-template-columns: 1fr 1fr;
      /* Display team members in a single column */
      row-gap: 20px;
      /* Add gap between team members */
      column-gap: 20px;

      .teamMember {
        img {
          width: 100%;
          background-color: #F5FBFF;
        }

        h3 {
          margin-bottom: 5px;
          /* Adjust margin for smaller screens */
          color: #1B3C74;
          font-weight: 600;
          font-size: 24px;
          /* Reduce font size for smaller screens */
        }

        p {
          margin-top: 5px;
          /* Adjust margin for smaller screens */
          color: #4B5160;
          font-size: 16px;
          /* Reduce font size for smaller screens */
        }
      }
    }
  }

  .topSection .underline {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column-reverse;
  }

  .topSection .underline p {
    width: 50px;
    margin-top: 5px;
  }
}

@media (max-width: 480px) {
  .ourTeam {
    margin-top: 50px;
    /* Adjust margin for smaller screens */

    h4 {
      text-align: start;
      color: #2490EB;
      font-size: 24px;
      /* Reduce font size for smaller screens */
    }

    h2 {
      text-align: start;
      color: #1B3C74;
      font-size: 36px;
      /* Reduce font size for smaller screens */
    }

    .mainforflex {
      display: grid;
      grid-template-columns: 1fr;
      /* Display team members in a single column */
      row-gap: 20px;
      /* Add gap between team members */

      .teamMember {
        img {
          width: 100%;
          background-color: #F5FBFF;
        }

        h3 {
          margin-bottom: 5px;
          /* Adjust margin for smaller screens */
          color: #1B3C74;
          font-weight: 600;
          font-size: 24px;
          /* Reduce font size for smaller screens */
        }

        p {
          margin-top: 5px;
          /* Adjust margin for smaller screens */
          color: #4B5160;
          font-size: 16px;
          /* Reduce font size for smaller screens */
        }
      }
    }
  }
}