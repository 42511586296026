@import url("https://fonts.googleapis.com/css2?family=Afacad:ital,wght@0,400..700;1,400..700&family=Rosario:ital,wght@0,300..700;1,300..700&display=swap");

.NewsSection {
  padding: 100px;
  // font-family: "Afacad", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;

  .trending {
    margin-top: 20px;
  }

  .text {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .news1,
    .news2,
    .news3,
    .news4,
    .news5 {
      display: flex;
      flex-direction: row;
      gap: 20px;

      .textone {
        text-align: start;
        width: 500px;

        h4 {
          font-size: 30px;
          font-weight: 500;
        }

        p {
          color: #1b3c74;
          font-weight: 400;
        }
      }
    }
  }

  // Mobile view adjustments
  @media screen and (max-width: 768px) {
    padding: 120px 20px 20px; // Top: 120px, Right and Left: 20px, Bottom: 20px
    display: block;

    .trending {
      margin-right: 0; // Adjust based on your layout preference
      margin-top: 10px;
    }

    .text {
      gap: 20px;

      .news1,
      .news2,
      .news3,
      .news4,
      .news5 {
        flex-direction: column;
        gap: 10px;

        .textone {
          width: auto;

          h4 {
            font-size: 20px; // Smaller font size for mobile
          }

          //   p {
          //     // Adjust if needed, but might be fine as is
          //   }
        }
      }
    }
  }
}