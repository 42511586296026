@import url("https://fonts.googleapis.com/css2?family=Afacad:ital,wght@0,400..700;1,400..700&family=Rosario:ital,wght@0,300..700;1,300..700&display=swap");
.NewsSection {
  padding: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.NewsSection .trending {
  margin-top: 20px;
}
.NewsSection .text {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.NewsSection .text .news1,
.NewsSection .text .news2,
.NewsSection .text .news3,
.NewsSection .text .news4,
.NewsSection .text .news5 {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.NewsSection .text .news1 .textone,
.NewsSection .text .news2 .textone,
.NewsSection .text .news3 .textone,
.NewsSection .text .news4 .textone,
.NewsSection .text .news5 .textone {
  text-align: start;
  width: 500px;
}
.NewsSection .text .news1 .textone h4,
.NewsSection .text .news2 .textone h4,
.NewsSection .text .news3 .textone h4,
.NewsSection .text .news4 .textone h4,
.NewsSection .text .news5 .textone h4 {
  font-size: 30px;
  font-weight: 500;
}
.NewsSection .text .news1 .textone p,
.NewsSection .text .news2 .textone p,
.NewsSection .text .news3 .textone p,
.NewsSection .text .news4 .textone p,
.NewsSection .text .news5 .textone p {
  color: #1b3c74;
  font-weight: 400;
}
@media screen and (max-width: 768px) {
  .NewsSection {
    padding: 120px 20px 20px;
    display: block;
  }
  .NewsSection .trending {
    margin-right: 0;
    margin-top: 10px;
  }
  .NewsSection .text {
    gap: 20px;
  }
  .NewsSection .text .news1,
  .NewsSection .text .news2,
  .NewsSection .text .news3,
  .NewsSection .text .news4,
  .NewsSection .text .news5 {
    flex-direction: column;
    gap: 10px;
  }
  .NewsSection .text .news1 .textone,
  .NewsSection .text .news2 .textone,
  .NewsSection .text .news3 .textone,
  .NewsSection .text .news4 .textone,
  .NewsSection .text .news5 .textone {
    width: auto;
  }
  .NewsSection .text .news1 .textone h4,
  .NewsSection .text .news2 .textone h4,
  .NewsSection .text .news3 .textone h4,
  .NewsSection .text .news4 .textone h4,
  .NewsSection .text .news5 .textone h4 {
    font-size: 20px;
  }
}/*# sourceMappingURL=News.css.map */