@import url("https://fonts.googleapis.com/css2?family=Afacad:ital,wght@0,400..700;1,400..700&family=Rosario:ital,wght@0,300..700;1,300..700&display=swap");
.faq-section {
  margin-top: 50px;
  margin-left: 50px;
  margin-right: 50px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f9f9f9;
  border-radius: 10px;
  text-align: start;
  background-image: url("https://assets-global.website-files.com/6551ed212e2ea32fb46bb7c8/65520ded00f687d201f5dc4d_bg.png");
}
.faq-section .underlinef {
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
  position: relative;
  z-index: 1001;
  width: 100%;
}
.faq-section .underlinef h5 {
  color: #B5539B;
  font-size: 25px;
}
.faq-section .underlinef p {
  border-bottom: 2px solid #B5539B;
  width: 200px;
  margin-top: 10px;
  margin-top: 0;
  margin-right: 20px;
}
.faq-section .faqInner {
  display: flex;
  flex-direction: row;
  gap: 50px;
}
.faq-section .faqInner img {
  height: 400px;
}

/* Heading Styling */
.faq-section h2,
.faq-section h3 {
  width: 100%;
  color: #1B3C74;
}

/* FAQ Item Styling */
.faq-item {
  margin-top: 40px;
  margin-bottom: 30px;
  background-color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* Question Styling */
.question {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px;
  background-color: #F5FBFF;
  border-radius: 5px;
}

.question h4 {
  margin: 0;
  color: #1B3C74;
}

.icon {
  float: right;
  transition: transform 0.4s ease-in-out;
}

.rotate {
  transform: rotate(45deg);
}

.active .answer {
  max-height: 500px;
  transition: max-height 0.4s ease-in-out;
}

/* Answer Styling */
.answer {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.4s ease-in-out;
}

.answer p {
  padding: 10px;
  border-top: 1px solid #ccc;
  color: #666;
}

@media screen and (max-width: 768px) {
  .faq-section {
    margin-left: 20px;
    margin-right: 20px;
  }
  .faq-section .faqInner {
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
  }
  .faq-section .faqInner img {
    height: 300px;
    width: 400px;
  }
  .underlinef p {
    width: 80px;
  }
}
@media screen and (max-width: 480px) {
  .faq-section {
    margin-left: 10px;
    margin-right: 10px;
  }
  .faq-section .faqInner {
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
  }
  .faq-section .faqInner img {
    height: 300px;
    width: auto;
  }
}/*# sourceMappingURL=FAQ.css.map */