@import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Afacad:ital,wght@0,400..700;1,400..700&family=Rosario:ital,wght@0,300..700;1,300..700&display=swap');


/* Navigation.css */
.navSec {
    position: fixed;
    z-index: 10000;
    width: 100%;
}

.navbar {
    background-color: #f8f9fa;
    /* Set background color */
    .container {
        display: flex;
        justify-content: space-between; /* Ensure space between brand and toggler */
        align-items: center; /* Vertically center items */
        padding-right: 20px; /* Add right padding for spacing */
        background-color: #f8f9fa;
    
        .LogoSection {
            display: flex;
            align-items: center;
    
            a {
                img {
                    width: 120px;
                    height: 70px;
                }
            }
        }
        .me-auto {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-left: 400px;
    
            .nav-link {
                color: #4f519f;
                text-decoration: none;
                font-size: 20px;
                font-weight: 500;
            }
    
            .nav-item {
                margin: 0 1rem;
    
                a {
                    color: #4f519f;
                    text-decoration: none;
                    // font-size: 20px;
                    font-weight: 500;
                }
    
                .dropdown-menu.show {
                    background-color: #e6e6e6;
                }
                .dropdown-menu {
                    --bs-dropdown-link-active-bg: #e6e6e6;
                }
            }
        }
    }
}



.navbar-brand img {
    width: 60px;
    /* Adjust logo width */
    height: 60px;
    /* Adjust logo height */
}

.navbar-toggler {
    border: none;
    /* Remove border */
    background-color: transparent;
    /* Set background color to transparent */
    color: #4f519f;
    /* Set font color */
}

.navItem {
    text-decoration: none; /* Remove underline */
    color: #4f519f; /* Set font color */
    font-size: 1.2rem; /* Set font size */
    font-weight: 700; /* Set font weight */
  }
  
  .navItem:hover {
    text-decoration: none; /* Remove underline on hover */
  }

.navbar-nav .nav-link {
    /* Set font family */
    font-size: 1.2rem;
    /* Set font size */
    font-weight: 700;
    /* Set font weight */
}

@media (max-width: 1400px){
    .me-auto {
        margin-left: 150px !important;
    }
}
@media (max-width: 768px) {
    .navbar-toggler {
        margin-left: auto;
        width: 100px;
        /* Use auto margin to push the toggler to the right */
    }
    .dropdown-menu.show {
        background-color: #e6e6e6;
        /* Adjust background color for dropdown menu */
    }
    .navbar-collapse{
        background-color: #E6E6E6;
        border: 2px solid #4f519f;
        border-radius: 10px;
        // margin: 10px;
        width: 100%;


    }
    .me-auto{
        margin-left: 0px !important;
    }
    .LogoSection {
        display: flex;
        align-items: center;
        margin-left: 20px;
        a {
            img {
                width: 60px;
                height: 60px;
            }
        }
    }
    
}

@media (max-width: 575.98px) {
    .Navbar__Collapse {
        background-color: #f8f9fa;
        /* Set background color */
    }
}

@media (max-width: 575.98px) {
    .navSec {
        width: 100%;
        /* Set width to 100% for full width on mobile */
    }

    .LogoSection {
        margin-bottom: 1rem;
        /* Add bottom margin for spacing */
        text-align: center;
        /* Center logo on mobile view */
    }

    .nav-item {
        margin: 0.5rem 0;
        /* Add margin for spacing between items */
        text-align: center;
        /* Center nav items on mobile view */
    }

    .dropdown-menu.show {
        background-color: #e6e6e6;
        /* Adjust background color for dropdown menu */
    }
}