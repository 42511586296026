
.contactSection {
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* Form container */
.contact-form {
  display: flex;
  flex-direction: row;
  background-color: #f9f9f9;
  border-radius: 5px;
  gap: 50px;
  margin-top: 40px;
  align-items: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.mainForm{
  width: 400px;
}
/* Heading style */


/* Input group container */
.input-group {
  position: relative;
  margin-bottom: 20px;
}

/* Icon style */
.icons {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  border-radius: 4px;
}

/* Input fields */
.input-group input,
.input-group textarea {
  width: 100%;
  padding: 10px;
  padding-left: 40px; /* Make space for icon */
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: none;
}

/* Textarea specific style */
.input-group textarea {
  height: auto !important;
}

/* Doctor and patient image */
.doctor-patient-image {
  max-width: 400px;
  height: 250px;
  border-radius: 5px;
}

/* Submit button style */
button[type="submit"] {
  background-color: #51A7EF; /* Green background color */
  color: white; /* White text color */
  padding: 15px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  border-radius: 5px;
  transition: opacity 0.3s ease;
}

button[type="submit"]:hover {
  opacity: 0.9;
}
@media (max-width: 768px) {
  .contact-form {
    flex-direction: column;
    gap: 20px;
    width: 300px;
    img{
      width: 300px;
      height: 200px;
    }
  }
  .mainForm{
    width: 300px;
  }
}