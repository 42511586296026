.footerSection {
  @import url("https://fonts.googleapis.com/css2?family=Afacad:ital,wght@0,400..700;1,400..700&family=Rosario:ital,wght@0,300..700;1,300..700&display=swap");

  display: flex;
  flex-direction: column;
  // font-family: "Afacad", sans-serif;

  .SectionOne {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-gap: 30px;
    margin-top: 5%;
    margin-left: 100px;
    margin-right: 100px;
    margin-bottom: 40px;
    border-bottom: 1px solid #8a8a8a;
    font-size: 20px;

    .img {
      width: 150px;
    }

    .contact {
      p {
        margin-bottom: 5px;
      }
    }

    .address {
      width: 300px;
    }

    .Icons {


      .socialIcons {
        display: flex;
        flex-direction: row;
        gap: 10px;

        a {
          .fab {
            vertical-align: middle;
          }
        }
      }

    }
  }

  .SectionTwo {
    display: flex;
    margin-left: 100px;
    margin-right: 100px;
    justify-content: space-between;
    margin-bottom: 20px;

    //flex-direction: column;
    .copyright {
      margin-left: 0%;
      margin-right: 0%;
      font-size: 20px;
    }

    .BT {
      p {
        font-size: 20px;

        a {
          text-decoration: none;
          font-weight: 700;
        }

        a span {
          color: #000;
          text-decoration: none;
          font-weight: 700;

        }
      }

    }
  }
}

@media only screen and (max-width: 768px) {
  .footerSection {
    align-items: center;
    /* Centering items vertically on smaller screens */
  }

  .footerSection .SectionOne,
  .footerSection .SectionTwo {
    margin-left: 20px;
    margin-right: 20px;
  }

  .footerSection .SectionOne {
    grid-template-columns: auto;
    /* Switching to a single column layout for smaller screens */
    padding-bottom: 20px;
  }

  .footerSection .SectionTwo {
    flex-direction: column;
    /* Displaying in column layout on smaller screens */
    align-items: center;
    /* Centering items horizontally on smaller screens */
  }
}