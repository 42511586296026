@import url('https://fonts.googleapis.com/css2?family=Afacad:ital,wght@0,400..700;1,400..700&family=Rosario:ital,wght@0,300..700;1,300..700&display=swap');


.serviceSection {
    padding-top: 100px;
    // font-family: "Afacad", sans-serif;
    padding-left: 100px;
    padding-right: 100px;
    margin-bottom: 50px;
    background-color: #F0F4F7;

    .bgservice {
        width: 100%;
        height: 500px;
        width: 900px;
        border-radius: 10px;

        .overlay {
            position: absolute;
            top: 60%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            color: #fff;
        }

        .overlay h1 {
            font-size: 24px;
            margin-bottom: 10px;
        }

        .overlay p {
            font-size: 16px;
            color: #ccc;
        }
    }

    .serviceDetails {
        padding-top: 50px;
        margin-left: 100px;
        margin-right: 100px;
    }

    .serviceDetails h4 {
        font-size: 24px;
        color: #1B3C74;
        margin-bottom: 20px;
    }

    .ServiceCard {
        display: grid;
        grid-template-rows: repeat(3, 1fr);
        justify-items: center;
        place-items: center;

    }

    .card {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-bottom: 30px;
        padding-left: 30px;
        background-color: #F0F4F7;
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        .cardImg {
            display: flex;
            justify-content: center;
            align-items: center;

        }

        .cardBody {
            h3 {
                font-size: 30px;
            }

            .points {
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: 70px;
                text-align: start;
                margin-left: 30px;

                p {
                    font-size: 20px;
                    color: #555555;
                    margin-bottom: 5px;
                }
            }
        }
    }

    .cardImg img {
        width: 500px;
        height: 350px;
        border-radius: 25px;
    }

    .cardBody {

        padding: 20px;
    }

    .cardBody h3 {
        font-size: 20px;
        color: #1B3C74;
        margin-bottom: 10px;
    }

    .points p {
        font-size: 16px;
        color: #555555;
        margin-bottom: 5px;
    }

}

@media (max-width: 1400px) {
    .serviceSection {
        padding-top: 120px;
        margin-left: 10px;
        margin-right: 10px;
        padding-left: 20px;
        padding-right: 20px;

        .bgservice {
            width: 100%;
            max-width: 900px;
            height: 500px;
        }

        .serviceDetails {
            margin-left: 20px;
            margin-right: 20px;

            .ServiceCard {
                max-height: 1300px;
                
            }
        }
    }

}

@media screen and (max-width: 950px) {
    .serviceSection {
        padding-top: 120px;
        margin-left: 20px;
        margin-right: 20px;
        padding-left: 20px;
        padding-right: 20px;

        .bgservice {
            width: 100%;
            height: 400px;
            object-fit: cover;
            border-radius: 10px;
            // width: 900px;
        }

        .overlay {
            top: 50%;
        }

        .overlay h1 {
            font-size: 20px;
        }

        .overlay p {
            font-size: 14px;
        }

        .serviceDetails {
            padding-top: 20px;
            /* Adjust as needed */
            margin-left: 20px;
            /* Adjust as needed */
            margin-right: 20px;

            /* Adjust as needed */
            .ServiceCard {
                max-height: 1900px;

                .card {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 30px;
                    padding-left: 0px;
                    background-color: #F0F4F7;
                    border-radius: 10px;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

                    .cardImg {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img {
                            width: 100%;
                            /* Make the image responsive */
                            height: auto;
                            /* Maintain aspect ratio */
                            border-radius: 10px;
                            /* Rounded corners */
                        }
                    }

                    .cardBody {
                        padding: 10px;
                        margin-top: 20px;

                        h3 {
                            font-size: 30px;
                        }

                        .points {
                            display: grid;
                            grid-template-columns: 1fr 1fr;
                            column-gap: 30px;
                            text-align: start;
                            margin-left: 10px;

                            p {
                                font-size: 20px;
                                color: #555555;
                                margin-bottom: 5px;
                            }
                        }
                    }
                }
            }
        }
    }

}


@media screen and (max-width: 480px) {
    .serviceSection {
        padding-top: 120px;
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .overlay {
        top: 40%;
    }

    .overlay h1 {
        font-size: 18px;
        width: 270px;
    }

    .overlay p {
        font-size: 12px;
    }

    .bgnews {
        height: 200px;
    }
    .ServiceCard{
        max-height: 1800px;

    }
}