/* Section.css */
@import url("https://fonts.googleapis.com/css2?family=Afacad:ital,wght@0,400..700;1,400..700&family=Rosario:ital,wght@0,300..700;1,300..700&display=swap");
.section {
  padding-top: 100px;
  margin-left: 100px;
  margin-right: 100px;
  margin-bottom: 100px;
}
.section .bgnews {
  width: 100%;
  height: 500px;
  width: 900px;
}

.overlay {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
}

.overlay h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.overlay p {
  font-size: 16px;
  color: #ccc;
}

.mission,
.vision {
  text-align: start;
  margin-top: 30px;
  margin-bottom: 30px;
}

.mission h4,
.vision h4 {
  font-size: 30px;
  margin-bottom: 10px;
}

.mission p,
.vision p {
  font-size: 20px;
  color: #555;
}

.values {
  display: flex;
  flex-direction: column;
}
.values h4 {
  font-size: 30px;
  margin-bottom: 20px;
  text-align: start;
}

/* Value cards container */
.value-card {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Adjust column width as needed */
  gap: 20px;
  align-items: start;
}

/* Individual value card */
.value-card-item {
  background-color: #ffcd5f; /* Gold color */
  border-radius: 10px; /* Rounded corners */
  min-width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 20px; /* Padding inside each card */
}

/* Icon within value card */
.value-card-item img {
  max-width: 100%;
  width: 20px;
  display: block;
  margin-bottom: 10px;
}

/* Title and description text */
.value-card-item h3,
.value-card-item p {
  margin: 10px 0;
  text-align: start;
}

/* Section.css */
/* Adjustments for 1024px viewport width */
@media (max-width: 1024px) {
  .section {
    padding-top: 120px;
    margin-left: 50px;
    margin-right: 50px;
  }
  .bgnews {
    width: 100%;
    max-width: 900px;
    /* Adjusted max-width */
    height: 500px;
  }
  .value-card-item {
    width: 23%;
    /* Adjusted width for value card items */
  }
}
/* Styles for screens larger than 768px */
/* Media query for screens up to 768px */
@media screen and (max-width: 768px) {
  .section {
    padding-top: 120px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .section .bgnews {
    width: 100%;
    height: 450px;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 10px;
  }
  .overlay {
    top: 50%;
  }
  .overlay h1 {
    font-size: 20px;
  }
  .overlay p {
    font-size: 14px;
  }
  .bgnews {
    width: 100%;
    height: 300px;
  }
}
/* Media query for mobile screens */
@media screen and (max-width: 480px) {
  .section {
    padding-top: 120px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .section .bgnews {
    height: 250px;
  }
  .overlay {
    top: 40%;
  }
  .overlay h1 {
    font-size: 18px;
  }
  .overlay p {
    font-size: 12px;
  }
  .value-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}/*# sourceMappingURL=About.css.map */